:root {
  --primaryColor: #aa70c1; /* rgb(170, 112, 193)*/
  --primaryColor-light: rgb(170, 112, 193, 0.1); /*#f6f1f9*/
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ymantraNav {
  background: var(--primaryColor) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  margin-bottom: 0;
}

.mr-auto.navbar-nav {
  position: absolute;
  right: 0;
}

.form {
  margin: 30px auto;
}

.form button {
  margin: 20px auto;
}

.form button.btn-default {
  margin: auto;
}

.form .form-group {
  display: flex;
}

.form .form-control {
  border: 1px solid #dddddd;
  border-radius: 0;
  margin-bottom: 20px;
  vertical-align: middle;
  padding: 0.275rem 0.75rem;
}
.search-clinic {
  padding: 4px 0px 4px 35px !important;
}

.form select.form-control {
  background-color: transparent;
}

.btn.btn-primary {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
}

.btn-selected {
  background: var(--primaryColor-light) !important;
  border-color: rgb(158, 158, 158, 0.1) !important;
  color: var(--primaryColor) !important;
}

.form .btn-primary {
  color: #fff;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  margin: 6px;
  min-width: 120px;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
}

span.sr-only {
  display: none;
}

.modal-header {
  font-size: 24px;
  color: var(--primaryColor);
  border-bottom: none;
  padding: 0px 20px;
  font-weight: 500;
}

.resetForm {
  display: inline;
}

.resetlink {
  cursor: pointer;
  min-width: 110px !important;
}

.back-link a {
  color: var(--primaryColor);
  text-decoration: none;
  font-size: 14px;
}

h1 {
  margin: 30px 0;
  font-size: 30px;
  font-weight: bold;
  color: var(--primaryColor);
}

.form .form-group label {
  text-align: right;
  color: #333;
  font-size: 15px;
}

.form.updatePassword label {
  text-align: right;
}

html {
  height: 100%;
}

div#root,
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 100%; */
  min-height: 100vh;
}

.Site-content--oval,
.Site-content {
  flex: 1;
}
.Site-header,
.Site-footer {
  flex: none;
  /* 2 */
}

.footer {
  background-color: #fafafa;
  color: #000;
  line-height: 40px;
  text-align: center;
}

.dropdown-menu .nav-link {
  color: #717171 !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: var(--primaryColor);
}

.dropdown-item,
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  background-color: var(--primaryColor);
  padding: 0.5rem 1rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #382956;
}

.nav-item.dropdown > a:after {
  display: none;
}

.col-form-label {
  margin-bottom: 20px;
  color: #999;
}
.care-team-section {
  max-height: 51px !important;
  max-width: 176px !important;
  min-width: 176px !important;
  overflow: auto !important;
  line-height: 23px;
  color: #999999;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  margin-left: 10px;
  padding: 5px;
}
/*care team scroll start*/
.care-team-section::-webkit-scrollbar {
  width: 16px;
  border-radius: 6px;
}
.care-team-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px #000;
}
.care-team-section::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  display: none;
}

.care-team-section::-webkit-scrollbar-button:single-button {
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
.care-team-section::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0px;
  border-color: #ffffff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-chevron-up%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M7.646%204.646a.5.5%200%200%201%20.708%200l6%206a.5.5%200%200%201-.708.708L8%205.707l-5.646%205.647a.5.5%200%200%201-.708-.708l6-6z%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.care-team-section::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23999999%22%20class%3D%22bi%20bi-chevron-up%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M7.646%204.646a.5.5%200%200%201%20.708%200l6%206a.5.5%200%200%201-.708.708L8%205.707l-5.646%205.647a.5.5%200%200%201-.708-.708l6-6z%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}
.care-team-section::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 0px;
  border-color: #ffffff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-chevron-down%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

.care-team-section::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23999999%22%20class%3D%22bi%20bi-chevron-down%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
}

/*care team scroll end*/

.care-team {
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  align-items: center;
}

.form-group.row > div {
  position: relative;
}

.eyePwd {
  position: absolute;
  right: -25px;
  width: 21px;
  top: 10px;
  color: var(--primaryColor);
}

.dashboard_page h2 {
  margin: 20px 0 0;
  font-size: 30px;
  font-weight: bold;
  color: var(--primaryColor);
}

.dashboard_page .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: 600;
  padding: 3px 20px;
  font-size: 15px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.ml-10 {
  margin-left: 10;
}

.dashboard_page .card h3 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  color: var(--primaryColor);
  margin-bottom: 5px;
  cursor: pointer;
}

.dashboard_page.container .card label {
  margin-bottom: 5px;
  word-break: break-all;
}

.dashboard_page.container .remove-btn {
  margin: auto;
  margin-top: 20px;
  display: block;
  width: 120px;
  font-size: 14px;
}

.dashboard_page.container .card {
  border: 1px solid #707070;
  margin-bottom: 15px;
  margin-top: 15px;
}

.dashboard_page.container .card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  font-size: 12px;
}

.dashboard_page.container .card label strong {
  font-weight: 500;
}

.dashboard_page.container table a.add-to-care-team-btn {
  margin: 0;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: normal;
}

.dashboard_page.container .table > :not(caption) > * > * {
  box-shadow: none;
}

.dashboard_page.container
  .table-hover
  > tbody
  > tr:hover
  a.add-to-care-team-btn {
  background: #fff;
  color: var(--primaryColor);
}

.dashboard_page.container table,
.dashboard_page.container .table-bordered > :not(caption) > *,
.dashboard_page.container .table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.dashboard_page.container .table-bordered > :not(caption) > * > * {
  vertical-align: middle;
}

.btn {
  border-radius: 3px !important;
}

.dashboard_page.container .search {
  margin-bottom: 30px;
}

.dashboard_page.container .search input {
  border: 1px solid #707070;
}

.dashboard_page.container .my_patients .scrollbar {
  height: 430px;
}

.dashboard_page .my_patients .count {
  background: #d91515;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 1111111111111111;
  width: auto;
  height: auto;
  border-radius: 50%;
  text-align: center;
  /* line-height: 30px; */
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
}
/*scroll bar for patient_excercises_page*/
.lib-scrollbar::-webkit-scrollbar,
.lib-scrollbar-customex::-webkit-scrollbar,
.clinical_notes-scrollbar::-webkit-scrollbar,
.report-scrollbar::-webkit-scrollbar,
.table-scrollbar::-webkit-scrollbar,
.reviewd_details--scrollbar::-webkit-scrollbar,
.pnotes_details--scrollbar::-webkit-scrollbar,
.excercise-scroll::-webkit-scrollbar,
.provider-scrollable-div::-webkit-scrollbar,
.reports_dashboard_page .dropdown-list::-webkit-scrollbar,
.branchPopup .clinics-scrollbar::-webkit-scrollbar,
.my-routine-mvcard::-webkit-scrollbar,
.selectwithlabel .clinics-scrollbar::-webkit-scrollbar,
.recard-container .recard-instruct::-webkit-scrollbar,
.save-routine-modal .routine-container::-webkit-scrollbar,
.scrollbar-custom::-webkit-scrollbar,
.orphanpat-scrollbar::-webkit-scrollbar,
.HEPBPopup .workout-scrollbar::-webkit-scrollbar,
.unireqapprovalview_container::-webkit-scrollbar,
.HEPBPopup-conf .wrconfirm-container::-webkit-scrollbar,
.HEPBPopup .wr-topsection::-webkit-scrollbar,
.scrollbar-lite::-webkit-scrollbar {
  /* donot add new class use this class .scrollbar-lite for scroll */
  width: 5px;
}
.lib-scrollbar::-webkit-scrollbar-track,
.lib-scrollbar-customex::-webkit-scrollbar-track,
.clinical_notes-scrollbar::-webkit-scrollbar-track,
.report-scrollbar::-webkit-scrollbar-track,
.table-scrollbar::-webkit-scrollbar-track,
.reviewd_details--scrollbar::-webkit-scrollbar-track,
.pnotes_details--scrollbar::-webkit-scrollbar-track,
.excercise-scroll::-webkit-scrollbar-track,
.provider-scrollable-div::-webkit-scrollbar-track,
.reports_dashboard_page .dropdown-list::-webkit-scrollbar-track,
.branchPopup .clinics-scrollbar::-webkit-scrollbar-track,
.my-routine-mvcard::-webkit-scrollbar-track,
.selectwithlabel .clinics-scrollbar::-webkit-scrollbar-track,
.recard-container .recard-instruct::-webkit-scrollbar-track,
.save-routine-modal .routine-container::-webkit-scrollbar-track,
.scrollbar-custom::-webkit-scrollbar-track,
.orphanpat-scrollbar::-webkit-scrollbar-track,
.HEPBPopup .workout-scrollbar::-webkit-scrollbar-track,
.unireqapprovalview_container::-webkit-scrollbar-track,
.HEPBPopup-conf .wrconfirm-container::-webkit-scrollbar-track,
.HEPBPopup .wr-topsection::-webkit-scrollbar-track,
.scrollbar-lite::-webkit-scrollbar-track {
  /* donot add new class use this class .scrollbar-lite for scroll */
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}
.lib-scrollbar::-webkit-scrollbar-thumb,
.lib-scrollbar-customex::-webkit-scrollbar-thumb,
.clinical_notes-scrollbar::-webkit-scrollbar-thumb,
.report-scrollbar::-webkit-scrollbar-thumb,
.table-scrollbar::-webkit-scrollbar-thumb,
.reviewd_details--scrollbar::-webkit-scrollbar-thumb,
.pnotes_details--scrollbar::-webkit-scrollbar-thumb,
.excercise-scroll::-webkit-scrollbar-thumb,
.provider-scrollable-div::-webkit-scrollbar-thumb,
.reports_dashboard_page .dropdown-list::-webkit-scrollbar-thumb,
.branchPopup .clinics-scrollbar::-webkit-scrollbar-thumb,
.my-routine-mvcard::-webkit-scrollbar-thumb,
.selectwithlabel .clinics-scrollbar::-webkit-scrollbar-thumb,
.recard-container .recard-instruct::-webkit-scrollbar-thumb,
.save-routine-modal .routine-container::-webkit-scrollbar-thumb,
.scrollbar-custom::-webkit-scrollbar-thumb,
.orphanpat-scrollbar::-webkit-scrollbar-thumb,
.HEPBPopup .workout-scrollbar::-webkit-scrollbar-thumb,
.HEPBPopup .wr-topsection::-webkit-scrollbar-thumb,
.unireqapprovalview_container::-webkit-scrollbar-thumb,
.HEPBPopup-conf .wrconfirm-container::-webkit-scrollbar-thumb,
.scrollbar-lite::-webkit-scrollbar-thumb {
  /* donot add new class use this class .scrollbar-lite for scroll */
  background: #999999;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
/* use this class for track visble for this lite scroll */
.visible-track-lite::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(184, 182, 182, 0.5);
}

/* scrollbars */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #382956;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #666;
}

.dashboard_page.container .scrollbar {
  padding-right: 5px;
  overflow-x: auto;
  overflow-y: auto;
}

.dashboard_page .patients_dir {
  position: relative;
}

.dashboard_page.container .my_patients .scrollbar {
  height: 430px;
}

.dashboard_page.container .patients_dir .scrollbar {
  height: calc(100vh - 228px);
}

.dashboard_page.container .patients_dir.my_patients .scrollbar {
  height: calc(100vh - 166px);
}

.dashboard_page.container .patients_dir.my_patients .bill-scrollbar {
  min-height: 184px;
  height: calc(100vh - 420px);
  /* margin-bottom: 15px; */
}

/* patient profile */

/* .patient_profile_page .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: bold;
  padding: 5px 30px;
  margin-right: 10px;
} */

.patient_profile_page .btn.active,
.patient_report_page .btn.active {
  background: #d4b7e0;
  border-color: #d4b7e0;
}

.patient_profile_page img {
  max-width: 100%;
}

.patient_profile_page .arrowDown {
  color: var(--primaryColor);
  width: 15px;
  height: 20px;
  margin-left: 5px;
}

/* .patient_profile_page .btn {
  display: inline-block;
  font-size: 14px;
  padding: 5px 20px;
} */

.patient_profile_page .btn.sm {
  width: 100px;
  padding: 5px;
}

.patient_profile_page .btn.md {
  width: 160px;
  padding: 5px;
}

.patient_profile_page .form .form-group label {
  text-align: left;
  width: 102px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 20px;
}

.patient_profile_page .form .form-group .col-form-label span.name {
  display: inline-block;
  padding-right: 20px;
}

.patient_profile_page .form .form-group .col-form-label {
  line-height: normal;
  padding: 0;
}

.patient_profile_page .form .form-group .col-form-label > div {
  width: 100%;
  display: flex;
  flex: inherit;
}

.patient_profile_page .form .form-group .col-form-label > div input {
  margin: 0;
  margin-right: 10px;
}

.arRow * {
  line-height: 3rem;
}

.ar {
  font-size: 3rem;
  color: var(--primaryColor);
  font-weight: bold;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.patient_profile_page .save .form-control {
  width: 90%;
}

.patient_profile_page button.btn.btn-primary,
.patient_report_page button.btn.btn-primary {
  margin: 0;
  padding: 0.375rem 0.75rem;
}

/* patient excercise */

.assignemntsPopup .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: bold;
  padding: 5px 30px;
  margin-right: 10px;
}
.patient_dashboard_page .btn,
.patient_profile_page .btn,
.clinical_notes_page .btn,
.stopwatch_inotes .btn,
.patient_report_page .btn,
.reports_dashboard_page .btn,
.progHistory .btn,
.patient_excercises_page .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  color: #fff;
  font-weight: bold;
  padding: 2px 10px;
  font-size: 14px;
  margin-right: 0px;
}
.helpdesk_page .Row_main_content,
.patient_report_page .Row_main_content,
.patient_profile_page .Row_main_content,
.clinical_notes_page .Row_main_content,
.reports_dashboard_page .Row_main_content,
.patient_excercises_page .Row_main_content,
.library_console .Row_main_content,
.customex-mainhead-wrapper {
  background: var(--primaryColor-light);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-top: 10px;
  margin-bottom: 5px;
}

.Library_title h1 {
  margin: 0px;
}
.Row_patient_title h1 {
  margin: 0px;
}
.Row_patient_title .a_mail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: #247afc;
}
.Row_patient_title .a_phone {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  color: #000000;
  margin: 5px 0px;
}
.Row_patient_title .emrid--txt {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.Row_main_content .list-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--primaryColor-light);
  /*rgb(226, 199, 237, 0.2);*/
  border: 1px solid rgb(158, 158, 158, 0.1);
  border-radius: 4px;
  /* font-size: 0.875em; */
}
.Row_main_content .list-group .list-group-item {
  background: transparent;
  color: #000;
  border: none;
}
.Row_main_content .list-group .list-group-item span {
  color: var(--primaryColor);
  margin-left: 5px;
}
.list-group-item-hide {
  display: none;
}
.list-group--hide {
  display: none;
}
.patient_excercises_page .btn.active {
  background: #d4b7e0;
  border-color: #d4b7e0;
}

.patient_excercises_page .btn.sm {
  width: 100px;
  padding: 5px;
}

.patient_excercises_page .btn.md {
  padding: 0px 10px 10px 10px;
}

.assignemntsPopup .card,
.patient_excercises_page .card,
.clinical_notes_page .card {
  margin-bottom: 5px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 1px 5px;
}

.patient_excercises_page .btn-link {
  border: none;
  text-decoration: none;
  color: #247afc;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
}

.patient_excercises_page .card-body,
.assignemntsPopup .card-body {
  flex: 1 1 auto;
  padding: 0.313rem;
}

.patient_excercises_page .card label strong {
  font-weight: 500;
}

.patient_excercises_page .form .form-group label {
  text-align: left;
  width: 102px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.patient_excercises_page .form .form-group .col-form-label span.name {
  display: inline-block;
  padding-right: 20px;
  width: 80%;
}

.patient_excercises_page .form .form-group .col-form-label {
  line-height: normal;
  padding: 0;
}

.patient_excercises_page .form .form-group .col-form-label > div {
  width: 100%;
  display: flex;
  flex: inherit;
}

.patient_excercises_page .form .form-group .col-form-label > div input {
  margin: 0;
  margin-right: 10px;
}

.patient_excercises_page .save .form-control {
  width: 90%;
}

.patient_excercises_page button.btn.btn-primary {
  padding: 0.375rem 0.75rem;
}

picture {
  background: #e3e3e3;
  min-width: 140px;
  min-height: 110px;
  display: block;
  position: relative;
}

.Deactivate picture {
  background: #333;
}

.Deactivate picture:before,
.Deactivate picture:after {
  display: none;
}

.Deactivate picture img {
  opacity: 0.1;
}

picture:before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 0%;
  font-size: 6rem;
  z-index: 0;
  width: 4px;
  height: 100%;
  background: #fff;
  transform: rotate(45deg);
  opacity: 0.8;
}

picture:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 0%;
  font-size: 6rem;
  z-index: 0;
  width: 4px;
  height: 100%;
  background: #fff;
  transform: rotate(-45deg);
  opacity: 0.8;
}

picture img {
  position: relative;
  z-index: 1;
}

.set-count {
  position: absolute;
  right: 10px;
  background-color: #d91515;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  border-radius: 50%;
  width: auto;
  height: auto;
  text-align: center;
  /* line-height: 30px; */
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a,
.link {
  color: #1892f8;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

a:hover,
.link:hover {
  color: #ccc;
}

.assignemntsPopup .set-count,
.patient_excercises_page .set-count {
  right: -4px;
  top: -15px;
}
.assignemntsPopup .fl,
.preview_exercise .fl,
.patient_excercises_page .fl {
  width: 55px;
  float: left;
}

.assignemntsPopup .fr,
.preview_exercise .fr,
.patient_excercises_page .fr {
  overflow: hidden;
}

.assignemntsPopup .view_count,
.patient_excercises_page .view_count {
  position: relative;
  /* display: inline-block; */
}

.assignemntsPopup .Deactivate.card,
.patient_excercises_page .Deactivate.card {
  background: #eae9ee;
  border-color: #eae9ee;
}

.clinical_notes_page .card h3,
.assignemntsPopup .card h3,
.patient_excercises_page .card h3 {
  margin: 0;
  font-weight: 600;
  color: var(--primaryColor);
  margin-bottom: 15px;
  line-height: normal;
}

.assignemntsPopup .card h4,
.patient_excercises_page .card h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #382956;
  margin: 0 0 2px 0;
  line-height: normal;
}

.assignemntsPopup .card h5,
.patient_excercises_page .card h5 {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--primaryColor);
  margin: 0 0 2px 0;
}

.assignemntsPopup .Deactivate.card h3,
.assignemntsPopup .Deactivate.card h3,
.assignemntsPopup .Deactivate.card h5,
.patient_excercises_page .Deactivate.card h3,
.patient_excercises_page .Deactivate.card h3,
.patient_excercises_page .Deactivate.card h5 {
  color: #666;
}

.assignemntsPopup .card .link {
  margin-bottom: 10px;
}

.patient_excercises_page .btn.remove-btn {
  margin-top: 20px;
}

.patient_excercises_page .mvcard,
.library_console .mvcard {
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  background: #ffffff;
}
.bill_dash_page .bill_dash_page-left-main-table {
  table-layout: fixed;
}
.bill_dash_page .bill_dash_page-left-main-table thead th {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  border-bottom-width: 0px;
}

.bill_dash_page .bill_dash_page-left-main-table td {
  border-bottom-width: 0px;
}
.bill_dash_page .availClaimcard {
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  background: #ffffff;
}
.bill_dash_page h2 span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 49px;
  color: #000000;
  margin-left: 5px;
}
.availClaimcard .ac-scrollbar {
  height: calc(100vh - 303px) !important;
  min-height: 400px !important;
  overflow: auto !important;
}
.availClaimcard .ac-scrollbar-in-treat {
  height: 144px !important;
  min-height: 144px !important;
  overflow: auto !important;
}
.availClaimcard .table tr td {
  border: 0 !important;
}
.patient_excercises_page .scrollbar {
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
}

.justify-content-md-center {
  justify-content: center !important;
}

.navbar-light .navbar-brand {
  padding: 0;
  margin: 0;
}

.movement_library_popup .scrollbar {
  height: 300px;
  overflow: auto;
}

button.close {
  position: absolute;
  right: 20px;
  top: -10px;
}

.movement_library_popup .table-hover > tbody > tr:hover {
  background: var(--primaryColor);
  color: #fff;
}

.movement_library_popup .mvmnt a {
  display: block;
  color: #333;
  font-weight: normal;
  padding: 5px 20px;
  transition: all 0.5s ease;
}

table tbody tr td.shortpara {
  overflow: hidden;
  max-width: 50px;
}

.movement_library_popup .mvmnt a:hover {
  background: var(--primaryColor);
  color: #fff;
}

.mvmnt .form-check input[type="radio"] {
  visibility: hidden;
  width: 0px;
  height: 0px;
  position: absolute;
  left: 0;
}

.mvmnt .form-check label.form-check-label {
  padding: 5px 20px;
  margin: 0;
  display: block;
  transition: all 0.5s ease;
  cursor: pointer;
}

.pointer.card .card-body {
  z-index: 1;
  min-height: 56px;
}

.mvmnt .form-check {
  padding: 0;
}

.mvmnt .form-check input[type="radio"]:checked + label.form-check-label,
.mvmnt .form-check label.form-check-label:hover {
  background: var(--primaryColor);
  color: #fff;
}

.movement_category_popup .select {
  position: relative;
  z-index: 0;
}

.text-align {
  text-align: center;
}

.movement_category_popup .select-side {
  right: 0;
}

.movement_category_popup label {
  display: block;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.movement_category_popup .select {
  position: relative;
  z-index: 0;
}

.movement_category_popup > .row {
  padding: 10px;
}
.alert-danger {
  overflow-wrap: break-word;
}

.ExercisePopup .video {
  /* position: absolute; */
  right: 80px;
  width: 400px;
  max-width: 100%;
}

.ExercisePopup .video .col {
  text-align: center;
}

.ExercisePopup .videoFrame {
  height: 140px;
  background: #707070;
  text-align: center;
  line-height: 140px;
  color: #fff;
  font-weight: bold;
}

.ExercisePopup button,
.printPopup button {
  padding: 6px 30px;
}

.ExercisePopup .action-buttons {
  text-align: center;
}

.counts button.btn.btn-primary {
  padding: 5px;
  min-width: 35px;
  margin: 0;
  font-size: 24px;
  height: 35px;
  padding-top: 0;
  line-height: 0;
  color: #55595c;
  background-color: #eceeef;
  border-color: #eceeef;
}

.countss {
  width: 312px;
  display: block;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.counts {
  width: 320px;
  display: flex;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  align-items: center;
}

.counts .input-group input {
  margin-bottom: 0;
  text-align: center;
}

.counts .input-group .input-group-prepend button,
.counts .input-group .input-group-append button {
  padding: 0;
}

.counts .input-group input:focus,
.counts .input-group .input-group-prepend button:hover,
.counts .input-group .input-group-prepend button:focus,
.counts .input-group .input-group-append button:hover,
.counts .input-group .input-group-append button:focus {
  box-shadow: none !important;
  border: 3px solid rgb(49 132 253 / 50%);
  padding: 0;
}

.counts span,
.counts label {
  display: flex;
  width: auto;
  float: left;
  margin-top: 3px;
  padding-right: 20px;
  margin-bottom: 3px !important;
}

.counts .input-group {
  display: flex;
  width: 150px;
}

.counts .input-group {
  display: flex;
  width: 150px;
  float: left;
  margin-right: 10px;
}

.counts input {
  margin: 0;
}

.selectDates {
  width: 320px;
  display: block;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.preview_exercise .card h3 {
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  color: var(--primaryColor);
  margin-bottom: 20px;
}

.preview_exercise .card h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #382956;
  margin: 0 0 20px 0;
}

.preview_exercise .card h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primaryColor);
  margin: 0 0 20px 0;
}

.form.assignemntsPopup.modal,
.form.ExercisePopup.modal {
  margin: auto;
}

.ExercisePopup label {
  margin-bottom: 5px;
}

.modal-body h2 {
  font-size: 2.1rem;
  color: var(--primaryColor);
  border-bottom: none;
  padding: 10px 0px;
  font-weight: bold;
}

.ExercisePopup .modal-header h2,
.printPopup .modal-header h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.HEPBPopup .modal-header,
.HEPBPopup-conf .modal-header,
.ExercisePopup .modal-header,
.printPopup .modal-header {
  border-bottom: 1px solid #dee2e6;
}

.ExercisePopup .delete-btn {
  color: #c00;
  border: none;
  font-weight: bold;
}

.disabled-btn {
  background-color: #999 !important;
  border: none;
  font-weight: bold;
  cursor: not-allowed;
}

.disabled-btn-color {
  background-color: #999 !important;
  cursor: not-allowed;
  border-color: #999 !important;
}

.ExercisePopup .disbaled-delete-btn {
  color: #999;
  border: none;
  font-weight: bold;
}

.ExercisePopup button.close {
  right: -11px;
  top: -18px;
}

.fa-times.close,
.fa-circle-xmark.close {
  cursor: pointer;
}

.fa-times.close:hover {
  opacity: 0.5;
}

button.close {
  z-index: 1;
  color: var(--primaryColor);
  font-size: 30px;
}

button.resetlink {
  border: none;
  background: transparent;
  color: #1892f8;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  padding-left: 0;
}
button.resetlink:hover {
  color: #cccccc;
}

.verificationCodePopup h2,
.resetPasswordPopup h2 {
  margin-top: 15px;
  font-weight: bold;
  font-size: 30px;
}

.verificationCodePopup .modal-body,
.resetPasswordPopup .modal-body {
  padding: 30px;
}

.recordingsPopup h2 {
  font-size: 24px;
  color: var(--primaryColor);
  padding-top: 5px;
  font-weight: bold;
}

.verificationCodePopup .back-btn {
  min-width: initial;
}

.recordingsPopup h3 {
  font-size: 20px;
  color: var(--primaryColor);
}

.recordingsPopup h4 {
  font-size: 18px;
  color: var(--primaryColor);
}

.recordingsPopup .card {
  margin-top: 25px;
  margin-right: 20px;
}

.recordingsPopup .card .card-body {
  padding: 0rem 1rem;
}

.recordingsPopup .card:first-child {
  margin-top: 0;
}

.recordingsPopup button.close {
  top: -10px;
  right: 10px;
}

.recordingsPopup .table-striped > tbody > tr {
  border-style: none !important;
  border-color: transparent;
  --bs-table-accent-bg: transparent !important;
}

.recordingsPopup td {
  vertical-align: middle;
  font-size: 14px;
  line-height: 25px;
  padding: 3px;
}

.recordingsPopup h2.sub {
  font-size: 20px;
}

.recordingsPopup h3 {
  font-size: 18px;
  color: var(--primaryColor);
  margin: 0;
  padding: 0;
}

.recordingsPopup h3.sub {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  margin: 8px 0 0;
}

.recordingsPopup h4 {
  font-size: 16px;
  color: var(--primaryColor);
  margin: 5px;
}

.recordingsPopup .modal-body {
  padding: 1rem 0.5rem 1rem 2rem;
}

.recordingsPopup .card-body table {
  margin: 0;
  min-height: 56px;
}

.recordingsPopup .card-body table .score {
  min-width: 184px;
}

.recordingsPopup td {
  color: #7c7190;
}

.assignemntsPopup .scrollbar,
.recordingsPopup .scrollbar {
  height: 280px;
  overflow: auto;
  padding-right: 5px;
  padding-left: 30px;
  margin-left: -30px;
  margin-top: 20px;
}

.recordingsPopup .modal-header {
  border-bottom: 1px solid #dee2e6;
}
.recordingsPopup .active.card:before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--primaryColor);
  position: absolute;
  content: "";
  left: -26px;
  top: 40%;
}

.recordingsPopup .novideo.card:before {
  width: 15px;
  height: 15px;
  /* background-image: url("images/novideo.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  left: -26px;
  top: 40%;
  -webkit-mask: url("images/novideo.svg") no-repeat 100% 100%;
  mask: url("images/novideo.svg") no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--primaryColor);
}

.recordingsPopup .Deactivate.card {
  background: #eae9ee;
  border-color: #eae9ee;
}

.recordingsPopup .Deactivate.card .link {
  color: #ccc;
}

.Deactivate.card .link.disabled {
  color: gray !important;
  pointer-events: none;
}

.recordingsPopup .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  margin-right: 30px;
  font-size: 14px;
}

.recordingsPopup.assignemntsPopup .active.card:before {
  display: none;
}

.assignemntsPopup .modal-header {
  min-height: 50px;
  border: inherit;
}

.back-link {
  color: #247afc;
  cursor: pointer;
}

.back-link:hover {
  color: #247afc;
  cursor: pointer;
}

.back-link svg {
  margin-right: 3px;
  font-size: 18px;
  vertical-align: middle;
  margin-top: -2px;
}

.back-link a {
  font-weight: normal;
  font-size: 16px;
}

.selectbox {
  position: relative;
  background: #ffffff;
  z-index: 1;
}

select.form-control,
select.form-control:focus {
  background-color: transparent;
  height: 35px;
}

.selectbox .select-side {
  right: 0;
}

a.btn.view-sm-btn {
  font-size: 13px;
  width: auto;
  padding: 2px 10px;
  margin-bottom: 0;
}

.pr-2 {
  padding-right: 2rem !important;
}

/* Auto Complete */
.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: -20px;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 96%;
  position: absolute;
  z-index: 111111;
  background: #eee;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: var(--primaryColor);
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid rgb(143, 139, 139);
}

.patient_dashboard_page h2 {
  font-size: 1.1rem;
  color: var(--primaryColor);
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.patient_dashboard_page h1 {
  text-align: center;
}

.patient_dashboard_page .card {
  border-color: #707070;
  margin-bottom: 10px;
}

/* .patient_dashboard_page .card.colr,
.patient_dashboard_page .card.coll {
  width: 48%;
  min-height: 100px;
  float: left;
} */

/* .patient_dashboard_page .card.colr {
  margin-left: 10px;
} */
.value_boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.value_boxes .card {
  width: 48%;
  min-height: 100px;
}

.patient_dashboard_page img {
  max-width: 100%;
}

.patient_dashboard_page > .row > div[class*="col-"] {
  /* padding: 5px; */
}

.patient_dashboard_page .main .row > div[class*="col-"] {
  padding: 5px;
}

.patient_dashboard_page .evaluation input {
  margin-bottom: 15px;
}

.patient_dashboard_page .evaluation button {
  padding: 5px 20px;
}

/* .patient_dashboard_page .card.coll,
.patient_dashboard_page .card.colr {
  padding-top: 20px;
  min-height: 136px;
} */

.patient_dashboard_page .sub_details .info > div,
.patient_dashboard_page .assign_details .info > div,
.patient_dashboard_page .patient_details .info > div {
  padding-bottom: 10px;
}

.patient_dashboard_page .main h3 {
  font-size: 30px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page .main .sets,
.patient_dashboard_page .main .reps {
  padding-top: 20px !important;
  text-align: center;
}

.patient_dashboard_page .main.set {
  padding-bottom: 10px !important;
  text-align: center;
  font-size: 14px;
  color: #707070;
  align-items: center;
  justify-content: center;
}

.patient_dashboard_page .main.set b.pointer:hover {
  color: blue;
}

.patient_dashboard_page .main.set strong,
.patient_dashboard_page .main.set a {
  margin: 5px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page .main.set a {
  margin: 5px 10px;
}

.patient_dashboard_page .main.set a.active {
  color: #247afc;
}

.patient_dashboard_page h4 {
  font-size: 18px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page h5 {
  font-size: 18px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page .card.main .card-body {
  padding: 0;
}

.patient_dashboard_page .assign_details.card svg {
  font-size: 18px;
  margin: 0 3px;
}

.patient_dashboard_page .assign_details.card svg.active {
  color: var(--primaryColor);
}

.patient_dashboard_page .assign_details.card svg.inactive {
  color: #b1b1b1;
}

.patient_dashboard_page .pain .info,
.patient_dashboard_page .diff .info,
.patient_dashboard_page .com .info {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page .com .info strong,
.patient_dashboard_page .pain .info strong,
.patient_dashboard_page .diff .info strong {
  font-size: 24px;
  padding-right: 5px;
  font-weight: bold;
}

.patient_dashboard_page .back-link {
  display: inline-block;
  margin: 15px 0;
}

.patient_dashboard_page .average_tempo .info {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #707070;
}

.patient_dashboard_page .average-section .info,
.patient_dashboard_page .score-section .info {
  text-align: center;
}

.add-pa {
  font-weight: 400;
  font-size: 15px;
  cursor: text;
}

.patient_dashboard_page .last_view .info {
  font-size: 14px;
  color: #707070;
}

.patient_excercises_page .back-link svg {
  font-size: 24px;
  font-weight: 100;
  margin-top: -3px;
}

.patient_excercises_page .back-link {
  display: inline-block;
  margin: 15px 0;
}

.patient_profile_page .back-link svg,
.clinical_notes_page .back-link svg,
.patient_report_page .back-link svg {
  font-size: 24px;
  font-weight: 100;
  margin-top: -3px;
}

.clinical_notes_page .back-link,
.patient_profile_page .back-link,
.patient_report_page .back-link {
  display: inline-block;
  margin: 15px 0;
}

.patient_dashboard_page .back-link svg {
  font-size: 24px;
  font-weight: 100;
  margin-top: -3px;
}

.input,
.select {
  position: relative;
}

.update_password_page,
.register_page,
.patient_signup_page,
.login_page,
.qr_fileupload_page {
  width: 650px;
  max-width: 100%;
  /* height: 100%; */
  min-height: 450px;
}

#registeration,
.set_password_page,
.update_password_page {
  /* height: 100%; */
  /* min-height: 450px; */
  flex: 1;
}

.close-button,
.btn-default {
  border: 1px solid #ccc;
}

.form.verificationCodePopup.modal .modal-dialog,
.form.resetPasswordPopup.modal .modal-dialog {
  max-width: 500px;
}

.verificationCodePopup .modal-footer,
.resetPasswordPopup .modal-footer {
  text-align: right;
  display: block;
  padding: 5px 20px;
}

.verificationCodePopup .modal-footer {
  padding: 15px 20px;
}

.verificationCodePopup .modal-header,
.resetPasswordPopup .modal-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.verificationCodePopup button.close,
.resetPasswordPopup button.close {
  top: 0;
  color: #333;
  font-weight: bolder;
}

.update_password_page .update-btn {
  min-width: 222px;
}

.profile_setup_page label {
  font-weight: bold;
}

.verificationCodePopup .form-group label,
.resetPasswordPopup .form-group label {
  text-align: left;
}

.dashboard_page .patients_dir .add-to-patient-directory-btn {
  right: 0;
  top: 0;
  margin: 0;
  margin-top: 0px;
  /* margin-bottom: 20px; */
  position: absolute;
}

label {
  color: #000;
}

.assignemntsPopup .edit-btn {
  min-width: 80px;
}

.assignemntsPopup .card {
  margin: 5px 30px;
  display: block;
}

.wrap {
  overflow: hidden;
}

.selectedDates ul {
  list-style: none;
  padding: 0;
}

.selectedDates {
  margin-top: 10px;
}

.selectedDates span {
  display: inline-block;
  width: auto;
}

.selectedDates span.date {
  margin-right: 10px;
  width: 200px;
}

.react-datepicker button {
  margin-top: 6px !important;
}

.selectDates .react-datepicker-popper {
  position: relative !important;
  transform: none !important;
}

.selectedDates span.remove {
  color: #c00;
  cursor: pointer;
  text-align: right;
}

.selectedDates li {
  border: 1px solid #ddd;
  padding: 10px;
  letter-spacing: 1px;
  margin: 1px;
  width: 250px;
}
.exercise-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 29px;
}

.datesList ul {
  list-style: none;
  padding: 0;
}

.datesList h4 {
  margin-bottom: 5px !important;
}

.datesList .assigned-date-list {
  height: 35px;
  overflow: auto;
  margin-bottom: 10px !important;
  max-width: 176px;
}

.datesList .assigned-date-list::-webkit-scrollbar {
  width: 3px;
}

.datesList {
  color: var(--primaryColor);
  font-weight: 500;
}

.datesList li {
  letter-spacing: 1px;
  margin: 1px;
}
.datesList .assigned-date-list--height {
  height: 70px;
}

.pointer {
  cursor: pointer;
  pointer-events: auto;
}

.videoWrapper {
  /* position: relative;
  height: 0;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); */
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a.mina {
  min-width: 28px;
}

.assign-btn {
  margin: auto;
  margin-top: 20px;
  display: block;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.patient_excercises_page .form .form-group .col-form-label > div input {
  margin: 3px 10px 3px 3px;
}

.pointer.card {
  position: relative;
}

.pointer.card .card-body {
  z-index: 1;
}

.pointer.card button.btn.remove-btn {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
  z-index: 11;
}

.excercise-scroll {
  overflow: auto;
  max-height: calc(100vh - 339px);
  padding-right: 5px;
  min-height: 460px;
  margin-bottom: 10px;
}

.excercise-scroll > div > div:last-child {
  margin-bottom: 0 !important;
}

.patients_dir.my_patients .pointer.heighter.card button.btn.assign-btn {
  position: unset !important;
  transform: unset !important;
  margin: auto auto 10px auto;
}

.pointer.card button.btn.assign-btn {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
  z-index: 11;
}

a.pointer:hover {
  color: var(--primaryColor);
  background-color: #b1b1b1;
}

.purple {
  color: var(--primaryColor);
}

.black {
  color: black;
}

.dashboard_page .patients_dir button.btn.add-to-care-team-btn {
  min-width: 150px;
  padding: 5px 10px;
  font-size: 14px;
}

/* .test {
  width: 560px;
  height: 315px;
} */
.test {
  width: 100%;
  /* height: 250px; */
  height: 190px;

  background-color: #ffffff;
}
#root > .test {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
}

.shortes {
  margin-top: 3px;
  margin-bottom: 0px;
}

.shortpara {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 170px !important;
}

.brkword {
  word-break: break-word;
}

.col-form-label.brkword {
  word-break: break-word;
}

.ExercisePopup .counts label {
  width: 60px;
}

select.dropdown-submission {
  border: 1px solid #ccc;
  color: var(--primaryColor);
  font-weight: bold;
  outline: none;
  background: #fff;
  padding: 3px 6px;
  width: 150px;
}

.reportlinks a {
  margin: 5px 5%;
  color: #333;
}

.reportlinks a.active-submission {
  color: var(--primaryColor);
}

.reportlinks {
  text-align: center;
  color: #ccc;
  background-color: #ffffff;
}

.error-message {
  width: 560px;
  height: 315px;
  padding: 10px;
}

/*patientExercise.js > completion card with 100% width according CAT-B exercise */
.custom-completion {
  width: 100% !important;
}

.video-container div[title="reviewrecord"] {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  padding-top: 56.25%;
  height: inherit !important;
  margin: -5px 0;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.sr-info h2 {
  margin-top: 0.6rem;
}

.sr-info strong {
  font-size: 24px;
  display: block;
  text-align: center;
  line-height: 2rem;
}

.sr-info span {
  line-height: 0.5rem;
  transform: rotate(56deg);
  font-size: 36px;
  left: 6px;
  position: relative;
  font-weight: 100;
  display: block;
  text-align: center;
}

.activeTest {
  color: blue;
  font: 18px;
}

.my_patients .pointer.card .card-body {
  padding-bottom: 58px;
}

.card.exerciseNote {
  min-height: 310px;
}

.patient_dashboard_page .card.score_view h2 span {
  color: #666;
  font-size: 16px;
  font-weight: normal;
}

.score_view.card {
  border-radius: 16px;
}

.patient_dashboard_page .card.score_view h2 {
  margin: 20px auto;
}

.asmntTable thead tr {
  border-bottom: 2px solid #fff !important;
}

.asmntTable thead tr {
  background: var(--primaryColor);
  color: white;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.asmntTable tbody tr:nth-child(odd) {
  background: var(--primaryColor-light);
}

.asmntTable tbody tr:nth-child(even) {
  background: var(--primaryColor-light);
}
.asmntTable tbody tr:hover {
  background-color: #ffffff;
}
.asmntTable tbody tr td:nth-child(2) {
  text-align: center;
}

.asmntTable {
  width: 500px;
  max-width: 100%;
}

.add-provider-modal .modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 15px;
}

.add-provider-modal .modal-header button.close {
  top: unset;
}

.add-provider-modal .add-to-care-team-btn {
  background: var(--primaryColor);
  color: #fff;
  font-weight: 600;
  padding: 6px 45px;
}

.add-provider-modal .suggestions {
  margin-top: 0;
}

/* Start Pain and difficulty tooptip Css */
#pain .google-visualization-tooltip,
#rating .google-visualization-tooltip,
#difficulty .google-visualization-tooltip,
#adherence .google-visualization-tooltip {
  padding: 10px;
  width: auto !important;
  height: auto !important;
}

#difficulty .google-visualization-tooltip a,
#pain .google-visualization-tooltip a,
#rating .google-visualization-tooltip a,
#adherence .google-visualization-tooltip a {
  display: block;
  margin: 0;
}

#pain .google-visualization-tooltip .toltipBox,
#rating .google-visualization-tooltip .toltipBox,
#difficulty .google-visualization-tooltip .toltipBox,
#adherence .google-visualization-tooltip .toltipBox {
  color: #000;
  text-align: left;
  margin-bottom: 2px;
  font-size: 13px;
}

#pain .google-visualization-tooltip .toltipBox .color-box,
#rating .google-visualization-tooltip .toltipBox .color-box,
#difficulty .google-visualization-tooltip .toltipBox .color-box,
#adherence .google-visualization-tooltip .toltipBox .color-box {
  padding: 0px 5px 0px 5px;
  height: 0px;
  margin-right: 5px;
  font-size: 10px;
}

/* End Pain and difficulty tooptip Css */

.video-loading-message {
  height: 246px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #000;
  color: #fff;
  font-weight: 600;
}
.no-video-message {
  height: 246px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #ffffff;
  color: #000000;
  font-weight: 600;
}

#google-visualization-errors-all-89 {
  display: none !important;
}

.evaluation.new-evaluation.card {
  min-height: 194px;
}

.info.new-info.row {
  margin-bottom: 5px;
  margin-top: 8px;
}

.patient_dashboard_page .patient_note {
  font-size: small;
  max-height: 250px;
  line-height: 26px;
  overflow: auto;
}

button.btn.btn-primary.COMPLETED {
  background: none;
  color: black;
}

.text-left.col-md-6.col-12.episodes {
  margin-top: 41px;
}

.Row_patient_title .selectbox {
  width: 252px;
  background: #ffffff;
}

/* Disabled Block */

.Deactivate.card {
  cursor: not-allowed;
}

.Deactivate.card .datesList h4 {
  color: gray;
  cursor: not-allowed;
}

.Deactivate.card .datesList ul li {
  color: gray;
  cursor: not-allowed;
}

.Deactivate.card .wrap .past-assignment-link {
  color: gray;
  cursor: not-allowed;
}

.Deactivate.card label {
  color: gray;
  cursor: not-allowed;
}

/* Success Message */
.register-success-message {
  position: absolute;
  width: auto;
  text-align: center;
  left: calc(50% - 160px);
}

.pointer-de {
  pointer-events: none !important;
}

.recordings-table tbody {
  border: 0 !important;
}

.recordings-table tbody td {
  --bs-table-accent-bg: unset !important;
  color: #7c7190 !important;
}

/* Patient Exercis screen*/
.patient_excercises_page .card:last-child {
  margin-bottom: 0px !important;
}
.patient_excercises_page .card:not(:not(.Deactivate)) {
  margin-top: 0px !important;
}

/* Header Css Changed */

header.ymantraNav.Site-header
  .container
  nav.navbar.navbar-expand-lg.navbar-light {
  padding: 0 !important;
}

.navbar-light .navbar-toggler {
  display: none;
}

.card-body .pointer.row:last-child label.pointer {
  margin: 0;
}

/* Set Password Css*/

.set_password_page {
  margin: 30px auto;
  max-width: 50%;
  min-width: 315px;
  width: 500px;
}

.set_password_page .form {
  margin: 0;
}

.set_password_page .form h1 {
  margin: 0 0 15px;
}

.set_password_page .form .form-group.row .form-label {
  font-weight: 500;
  font-size: 16px;
}

.set_password_page .text-links.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.set_password_page .text-links.text-center a {
  margin: 0;
  padding-left: 5px;
}

.set_password_page.container .form .form-group.row {
  margin-top: 15px;
}

.set_password_page.container .form .form-group.row .col .input input {
  margin: 0;
}

.set_password_page.container
  .form
  .form-group.row
  .col
  .input
  .alert.alert-danger {
  background: transparent;
  border: 0;
  padding: 5px 0 0;
  margin: 0;
}

.set_password_page .form.action.text-center {
  margin-top: 10px;
}

/* ReactplayerUI */

.video-container {
  position: relative;
}

.video-container .expandIconOverlay {
  position: absolute;
  color: #ffffff;
  cursor: pointer;
  background-color: #000;
  border-radius: 50%;
  top: 0;
  right: 0;
  padding: 1em;
  margin: 1em;
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}
.expandIconOverlay:hover {
  opacity: 0.8;
}
.video-container .IconOverlay-full {
  color: #ffffff;
  background-color: #707070;
  font-size: 1.2em;
}

.overlay-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  height: fit-content;
}
.overlay-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: auto;
}

.overlay-container .card {
  overflow: hidden;
  padding: 20px;

  /* max-width: 250px; */
  max-width: 300px;
}
.overlay-item1 {
  width: 130px;
}
.overlay-item2 {
  /* width: 250px; */
  width: 300px;
}
.vc--full div[title="reviewrecord"] {
  width: 75% !important;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-volume-control-container,
video::-webkit-media-controls-volume-control-hover-background {
  display: none;
}

.episode-dropdown .MuiSelect-select {
  padding: 5px 10px 5px 10px;
  text-align: left !important;
}

.episode-dropdown fieldset legend {
  width: 0;
}
.MuiList-root .MuiButtonBase-root:hover {
  background-color: rgb(170 112 193) !important;
  color: #fff !important;
  opacity: 1;
}

.Mui-selected {
  background-color: rgb(170 112 193) !important;
  color: #fff !important;
  opacity: 0.7;
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  margin-bottom: 0px !important;
}

/*stopwatch*/
.Btngroup--border {
  border: 1px solid var(--primaryColor);
  border-radius: 6px;
}
.Btngroup--border button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.stopwatch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 95px;
  padding: 0px 5px;
}
.stopwatch--lefticon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
}
.stopwatch--lefticon svg {
  color: var(--primaryColor);
}
.stopwatch--timer span {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5;
  color: var(--primaryColor);
}
.stopwatch--timer .curmonth {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: var(--primaryColor);
}
.stopwatch--btnactive:hover {
  background: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  opacity: 0.8;
  /* box-shadow: 0px 2px 10px 5px rgb(170, 112, 195, 0.9); */
}
/* .stopwatch--btnactive:hover .svgoutline path {
  fill: var(--primaryColor);
} */
.stopwatch--rightbtn:hover {
  box-shadow: 0px 2px 5px 0.1px var(--primaryColor);
  opacity: 0.8;
}

.stopwatch--btnactive {
  transform: scale(0.9, 0.9);
  box-shadow: none;
}
.col__stopwatch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col__stopwatch .btn-group > .btn:not(:first-child) {
  margin-left: 0px;
}
/*feedback modal*/

.feedbackPopup .modal-dialog,
.form.customex-modal.modal .modal-dialog {
  max-width: 450px;
}
.feedbackPopup .modal-header {
  padding: 0px 10px 0px 15px;
}
.feedbackPopup .modal-body {
  padding: 0px 1rem;
}
.feedbackPopup .modal-footer {
  border: 0;
  padding: 0px 0.75rem 0.75rem 0.75rem;
}
.HEPBPopup .clock-on-model-top,
.ExercisePopup .clock-on-model-top,
.feedbackPopup .clock-on-model-top {
  background-color: var(--primaryColor);
  color: #ffffff;
  border-radius: 6px 6px 0px 0px;
  width: 96px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: absolute;
  transform: translateY(-100%);
  z-index: 9997;
}
.HEPBPopup .clock-on-model-top span,
.ExercisePopup .clock-on-model-top span,
.feedbackPopup .clock-on-model-top span {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
.feedbackPopup h2,
.inotesPopup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1;
  color: var(--primaryColor);
  margin: 10px 0px;
}
.feedbackPopup .btn-primary,
.inotesPopup .btn-primary {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: 600;
  padding: 3px 20px;
  font-size: 14px;
  min-width: 0px;
}
.feedbackPopup .btn-default,
.inotesPopup .btn-default {
  border: 1px solid #cccccc;
  color: #0e0e0e;
  font-weight: 600;
  padding: 3px 20px;
  font-size: 14px;
  min-width: 0px;
  margin: 0px !important;
}
.inotesPopup .form-check-input:checked,
.feedbackPopup .form-check-input:checked,
.customex-container .form-check-input:checked,
.ExercisePopup .form-check-input:checked,
.HEPBPopup .form-check-input:checked,
.HEPBPopup-conf .form-check-input:checked {
  background-color: var(--primaryColor);
  border-color: rgba(191, 191, 191, 0.8);
}
.form-check-input:focus {
  border-color: rgba(191, 191, 191, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(191, 191, 191, 0.8);
  outline: 0 none;
}

.feedbackPopup label,
.inotesPopup label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 5px;
}
.feedbackPopup p,
.inotesPopup p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  white-space: pre-wrap;
  word-break: break-word;
}
.feedbackPopup .report-feedbacktime {
  background: #ffffff;
  border: 1px solid var(--primaryColor);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.feedbackPopup .report-feedbacktime span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
}
.feedbackPopup .alert {
  padding: 0.5rem 1rem;
  margin-bottom: 0px;
}
.feedbackPopup textarea {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  resize: none;
  margin-bottom: 10px !important;
}
.retun-feedbackPopup .modal-body,
.confirmPopup .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.retun-feedbackPopup .modal-body p,
.confirmPopup .modal-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  word-break: keep-all;
}
.confirmPopup .modal-body p {
  padding: 0px 10px;
}
.retun-feedbackPopup .retun-feedbackPopup--btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmPopup .confirmPopup--btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.retun-feedbackPopup--btn .btn-default,
.confirmPopup .confirmPopup--btn .btn-default {
  border: 1px solid #cccccc;
  color: #0e0e0e;
  font-weight: 600;
  padding: 3px 20px;
  font-size: 14px;
  min-width: 0px;
  margin: 0px !important;
}
.retun-feedbackPopup--btn .btn-primary,
.confirmPopup .confirmPopup--btn .btn-primary {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: 600;
  padding: 3px 20px;
  font-size: 14px;
  min-width: 0px;
}

.branchPopup {
  z-index: 9998;
}
.branchPopup--backdrop {
  z-index: 9998;
}
.retun-feedbackPopup .modal-dialog,
.branchPopup .modal-dialog,
.confirmPopup .modal-dialog {
  max-width: 450px;
}
.branchPopup .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 1rem 0.5rem 1rem;
}

.branchPopup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: var(--primaryColor);
  padding: 10px;
}
.branchPopup--btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.branchPopup--btn .btn-primary {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: 700;
  /* padding: 8px 16px; */
  font-size: 14px;
  min-width: 0px;
  line-height: 19px;
}
.feedbackPopup input[type="radio"] {
  margin-bottom: 15px;
  margin-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.recard-container input[type="radio"]:checked,
.feedbackPopup input[type="radio"]:checked,
.branchPopup input[type="radio"]:checked {
  accent-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}
.branchPopup .branchPopup--radiobtn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  word-break: break-all;
  margin-bottom: 10px;
}
.branchPopup .branchPopup--radiobtn div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.branchPopup input[type="radio"] {
  margin-right: 5px;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.branchPopup--radiobtn-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reviewd_details {
  max-height: 260px;
  overflow: hidden;
}
.reviewd_details .reviewd_details--scrollbar {
  /* height: calc(100vh - 310px) !important; */
  max-height: 260px !important;
  overflow: auto !important;
  padding: 5px;
}

.reviewd_details .infinite-scroll-component,
.inotesPopup .infinite-scroll-component {
  overflow: hidden !important;
}
.cnReviewCard .cnReviewCard--by,
.sessReview .sessReview--by {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
}
.cnReviewCard .cnReviewCard--by span,
.sessReview .sessReview--by span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.cnReviewCard .cnReviewCard--on,
.sessReview .sessReview--on {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--primaryColor);
}
.cnReviewCard .cnReviewCard--on span,
.sessReview .sessReview--on span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}
.cnReviewCard p {
  white-space: pre-wrap;
}
.sessReview .sessReview--eval,
.sessReview .sessReview--observ {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.sessReview .sessReview--eval span,
.sessReview .sessReview--observ span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.show-more-div {
  background: #f4f3f3;
  border: 1px solid #cccccc;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}
.show-more-div .show-more-clickable {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-more-clickable:hover {
  cursor: pointer;
}
.show-more-div .show-less-clickable {
  padding: 5px;
}
.show-more-div .show-less-clickable svg {
  transform: rotate(180deg);
}

.reports_dashboard_page .Row_main_content,
.clinical_notes_page .Row_main_content {
  min-height: calc(100vh - 100px);
}

.cnReviewCard--on,
.cnReviewCard--viewbtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.cnReviewCard--viewbtn {
  margin-top: 10px;
}
.cnReviewCard--on div span {
  margin-left: 5px;
}
.cnReviewCard--on div:nth-child(1) {
  margin-right: 25px;
}
.cnReviewCard--on div:nth-child(2) {
  margin-right: 10px;
}

.cnReviewCard .show-more-less-clickable {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--primaryColor);
  text-decoration: none;
}
.div-more-less-show span > span:nth-child(1) > span:nth-child(1) > span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 5px;
}

.cnReviewCard label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 5px;
}

.cnReviewCard p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.flag-red {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.clinical_notes_page .clinical_notes-scrollbar {
  height: calc(100vh - 303px) !important;
  min-height: 430px !important;
  overflow: auto !important;
  padding: 10px;
}
.clinical_notes_page .infinite-scroll-component {
  overflow: hidden !important;
}
.clinical_notes_page .cnReviewCard--observ::first-line {
  line-height: 0;
}

.search-select-dropdown {
  cursor: pointer;
  padding: 10px 20px;
  width: 220px;
  background-color: #fff !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  margin-left: 5px;
  border: 1.3px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.active {
    box-shadow: 0px 0px 0px 3px rgb(52 136 255 / 66%);
  }
}
.search-select-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 12px;
  width: 220px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 7px;
  /* max-height: 144px;
  overflow-y: auto;
  overflow-x: hidden; */
  margin-top: 2px;
}
.select-dropdown-search {
  margin: 5px;
}
.search_image {
  width: 13px;
  height: 15px;
  top: 3px !important;
  left: 30px;
}
.search_field {
  width: 210px;
  height: 31px;
  border: 1px solid #c4c4c4;
  border-radius: 7px;
  font-size: 14px;
  padding: 12px 20px 12px 34px !important;
}
.select-dropdown-values {
  cursor: pointer;
}
.select-dropdown-value {
  text-align: start;
  padding: 3px 0px 3px 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-dropdown-value:hover {
  background-color: var(--primaryColor);
  color: #fff;
}
.selectBoxBranch {
  width: 220px;
  background-color: #ffffff !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #212529;
  margin-left: 5px;
}

.selectwithlabel .form-label {
  margin-bottom: 1px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 20px;
}
.select-side {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 34px;
  height: 34px; */
  z-index: 1;
  color: #333;
  line-height: 37px;
  text-align: center;
  pointer-events: none;
}
@media (max-width: 768px) {
  .col__stopwatch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .cnReviewCard--on,
  .cnReviewCard--viewbtn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .overlay-container {
    display: none;
  }
}
@media (min-width: 650px) and (max-width: 768px) {
  .overlay-container {
    display: flex;
    width: 30%;
  }
  .overlay-container .card {
    overflow: hidden;
    padding: 15px;
    max-width: 80%;
  }
  .overlay-item {
    padding: 0px;
  }
  .overlay-item1 {
    padding: 0px !important;
    display: flex;
    justify-content: flex-start !important;
  }
  .overlay-item2 {
    display: flex;
    justify-content: flex-start;
  }
  .overlay-item1 .card {
    margin-bottom: 0px;
  }
  .overlay-item2 .card {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .vc--full div[title="reviewrecord"] {
    width: 60% !important;
  }
}
/* Report page css*/
.loading-section {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiFormGroup-root .MuiTypography-root {
  font-size: 14px !important;
}

select.form-control {
  padding: 0.3rem 0.75rem;
}
select {
  padding: 0 1.25rem 0 0.625rem !important;
  -webkit-padding-end: 1.25rem !important;
  -webkit-padding-start: 0.625rem !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.withoutselectbox:disabled {
  background-color: transparent;
  -webkit-padding-end: 0.25rem !important;
}
.withoutselectbox:read-only {
  background-color: transparent;
}
.exercise-image-deault {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  object-fit: contain;
}
.exercise-image-deault img {
  display: block;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 150px;
  max-height: 130px;
  object-fit: fill;
}

.careteamCol {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.careteamCol .careteamDiv {
  display: flex;
  justify-content: space-between;
}

.rtm-switch .form-check {
  display: flex;
  align-items: center;
}
.rtm-switch label {
  color: var(--primaryColor);
  font-weight: bold;
}
.rtm-switch .form-switch {
  padding: 0px;
}
.col--tooltip {
  display: inline-block;
  padding: 0px;
}
.icon--help {
  font-size: 14px;
  color: #707070;
}

.rtm-switch .form-check .form-check-input {
  transform: scale(1.3);
  margin: 10px;
}
.rtm-switch .form-check .form-check-input:focus {
  box-shadow: 0 0 0 0 !important;
}
.rtm-switch .form-check .form-check-input:not(:checked) {
  border: 1px solid var(--primaryColor) !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/></svg>");
}
.rtm-switch .form-check .form-check-input:checked {
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
}

.rtm-switch .form-check-input {
  margin-left: 10px !important;
  float: none !important;
  padding-left: 0px;
}

.row-optrtm {
  margin-bottom: 10px;
}
.row-optrtm .col {
  display: flex;
  align-items: center;
  max-width: fit-content;
}
.printPopup .modal-dialog {
  width: 500px;
}
.printPopup .printPopup_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.printPopup_container_preview {
  /* width: 297px;
  height: 421px; */
  width: 236px;
  height: 333px;
  min-height: fit-content;
  border: 1px solid #c4c4c4;
}
.printPopup_container_pageno {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.printPopup .pageno_box {
  width: 31px;
  height: 20px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.printPopup_container_arrows {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px;
}
.printPopup_container_arrows .active-arrow {
  color: var(--primaryColor);
}
.printPopup_container_arrows .deactive-arrow {
  color: #c4c4c4;
}
.printPopup_container_preview {
  padding: 10px;
}
.printPopup_container_preview h3 {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 13px;
  color: var(--primaryColor);
}
.printPopup_container_preview h4 {
  font-weight: 600;
  font-size: 0.4375rem;
  line-height: 9px;
}
.printPopup_container_preview h5 {
  font-weight: 600;
  font-size: 0.4375rem;
  line-height: 9px;
  color: var(--primaryColor);
}
.printPopup_container_image {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.printPopup_container_image img {
  border-radius: 6px;
  width: 46px;
  height: 52px;
}
.printPopup_container_desc-title {
  font-weight: 600;
  font-size: 0.5rem;
  line-height: 11px;
}
.printPopup_container_desc {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 11px;
  text-align: justify;
}

.printPopup_container_preview .datesList ul {
  list-style: none;
  padding: 0;
}

.printPopup_container_preview .datesList h4 {
  margin-bottom: 2px !important;
}

.printPopup_container_preview .datesList .assigned-date-list {
  height: fit-content;
  overflow: visible;
  margin-bottom: 0px !important;
  max-width: fit-content;
  font-size: 0.4375rem;
}

.printPopup_container_preview
  .datesList
  .assigned-date-list::-webkit-scrollbar {
  width: 3px;
}
.search-select-container::-webkit-scrollbar {
  width: 3px;
}
.printPopup_container_preview .datesList {
  color: #000;
  font-weight: 500;
}

.printPopup_container_preview .datesList li {
  letter-spacing: 1px;
  margin: 1px;
}
.printPopup_container_preview .datesList .assigned-date-list--height {
  height: 70px;
}

.print-icon--hover:hover {
  cursor: pointer;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 18pt;
  }
  .printPopup_container_preview {
    border: 0px;
  }
  .printPopup_container_preview h3 {
    line-height: 1.5rem;
    font-size: 1.5rem;
    padding-bottom: 10px;
    /* color:#000000 !important; */
  }
  .printPopup_container_preview h4 {
    line-height: 1.5rem;
    font-size: 1.1rem;
    padding-bottom: 10px;
  }
  .printPopup_container_preview h5 {
    line-height: 1.5rem;
    font-size: 1.1rem;
    /* color:#000000 !important; */
  }
  .printPopup_container_desc-title {
    line-height: 1.5rem;
    font-size: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .printPopup_container_desc {
    line-height: 1.5rem;
    font-size: 1rem;
  }
  .printPopup_container_image img {
    width: 90px;
    height: 105px;
  }
  .printPopup_container_preview .datesList .assigned-date-list {
    font-size: 1rem;
  }
  img {
    height: 200px !important;
    width: 200px !important;
  }
}

@media print {
  body {
    display: block;
  }
  .page-break {
    /* margin-top: 1rem;  */
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.btn:focus {
  box-shadow: none !important;
}

div.google-visualization-tooltip {
  pointer-events: none;
}

.table__patdir {
  border-collapse: separate;
  border-spacing: 0 2px;
  margin-bottom: 0px;
}
.table__patdir thead {
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.99);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.99);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.99);
}
.tr__inactivepatrow {
  background-color: rgb(204, 204, 204, 0.3);
}

/*reports RTM*/
.Row_patient_report {
  margin: 20px 0px;
}
.Row_patient_report h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
}

.Row_patient_report .report-card {
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  background: #ffffff;
}
.Row_patient_report .dwld-card {
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  background: #ffffff;
  min-height: 150px !important;
  /* min-width: 350px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.dwld-card a {
  margin-bottom: 0px;
}
.report-card span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}
.report-card .report-scrollbar {
  height: calc(100vh - 303px) !important;
  min-height: 260px !important;
  overflow: auto !important;
  padding: 10px;
}

.Row_patient_report .btn-dwld {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  padding: 2px 10px;
}
.Row_patient_report .btn-dwld--disable {
  background: #999999 !important;
  cursor: not-allowed !important;
  border-color: #999999 !important;
}
.Row_patient_report--firstcol {
  display: flex;
  flex-direction: column;
}
.Row_patient_report--firstcol .row:first-of-type {
  margin-bottom: 20px;
}

/* media queries */
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    right: 0;
  }
}

/* @media (max-width: 767px) {
  .eyePwd {
    right: 20px;
  }
} */

@media (min-width: 768px) {
  .dashboard_page .my_patients {
    margin-right: 5%;
  }
}

@media (max-width: 767px) {
  .dashboard_page .patients_dir tr,
  .dashboard_page .patients_dir td {
    display: block;
    width: 100%;
  }

  .dashboard_page .patients_dir td {
    position: relative;
    display: inline-block;
  }

  .dashboard_page .patients_dir tr td:first-child:after {
    /*border-bottom: 1px solid #ccc; */
    position: absolute;
    left: 0;
    right: 0;
    top: -5px;
    width: 100%;
    content: "";
  }

  .dashboard_page .patients_dir tr {
    padding-bottom: 15px;
  }

  .dashboard_page .patients_dir th {
    display: inline-block;
  }

  .dashboard_page .patients_dir td::before {
    content: "";
    position: relative;
    left: 0;
    top: 0;
    display: inline-block;
    font-weight: bold;
  }

  .dashboard_page .patients_dir td:nth-child(1):before {
    /* content: "Name: "; */
    margin-right: 5px;
  }

  .dashboard_page .patients_dir td:nth-child(2):before {
    /*   content: "Age: ";*/
    margin-right: 5px;
  }

  .dashboard_page .patients_dir td:nth-child(3):before {
    /* content: "Condition: ";*/
    margin-right: 5px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .mb-2.patients_dir.my_patients
    .scrollbar
    .pointer.heighter.card
    .card-body
    .pointer.row
    .col-xl-7.col-lg-12 {
    padding: 0;
  }
}
@media (max-width: 968px) {
  .patient_profile_page button.btn.btn-primary,
  .clinical_notes_page button.btn.btn-primary,
  .patient_report_page button.btn.btn-primary {
    min-width: inherit;
  }
}

@media (max-width: 767px) {
  .patient_profile_page .text-right {
    text-align: left;
  }

  .patient_profile_page .save .form-control {
    width: 95%;
  }

  .patient_profile_page button.btn.btn-primary,
  .clinical_notes_page button.btn.btn-primary,
  .patient_report_page button.btn.btn-primary {
    padding: 5px 20px;
    min-width: inherit;
    margin: 0;
  }

  .patient_profile_page .btn.md {
    margin-top: 0;
  }
}

@media (max-width: 968px) {
  .patient_excercises_page button.btn.btn-primary {
    min-width: inherit;
  }
}

@media (max-width: 767px) {
  .patient_excercises_page .text-right {
    text-align: left;
  }

  .patient_excercises_page .save .form-control {
    width: 95%;
  }

  .patient_excercises_page button.btn.btn-primary {
    padding: 5px 20px;
    min-width: inherit;
    margin: 0;
  }

  .patient_excercises_page .btn.md {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .smf {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .patient_excercises_page .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .patient_excercises_page .mb-1.text-right,
  .patient_excercises_page .mb-2.text-right {
    display: inline-block;
  }

  .form .form-group label {
    text-align: left;
    margin-bottom: 0;
  }

  .mr-auto.navbar-nav {
    position: relative;
    right: inherit;
  }
}

@media (max-width: 567px) {
  .patient_excercises_page .mb-1.text-right,
  .patient_excercises_page .mb-2.text-right {
    text-align: left;
    margin-bottom: 5px !important;
  }
}

.navbar-collapse.collapse {
  display: contents !important;
}

@media (max-width: 767px) {
  .navbar-collapse.collapse {
    display: block;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  button.navbar-toggler {
    display: none;
  }

  .mr-auto.navbar-nav > * {
    display: inline-block;
    margin: 5px;
  }

  .mr-auto.navbar-nav {
    display: inherit;
  }

  .dropdown-menu.show {
    position: absolute;
    right: -8px;
    top: 48px;
  }
}

@media (min-width: 800px) {
  .modal-dialog {
    max-width: 800px;
  }
}

@media (min-width: 768px) {
  .preview_exercise .card-body {
    padding: 2rem 2rem 1rem;
  }
  .preview_exercise .smf {
    font-size: small;
  }
  .dropdown-menu.show {
    position: absolute;
    right: -8px;
    top: 40px;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (max-width: 767px) {
  .ExercisePopup.form .btn-primary {
    min-width: initial;
  }
}
@media (min-width: 800px) {
  .recordingsPopup .modal-dialog {
    max-width: 1000px;
  }

  .recordingsPopup .modal-header {
    border-bottom: 1px solid #ddd;
    padding: 5px 20px;
  }
}
@media (max-width: 800px) {
  .recordingsPopup td,
  .recordingsPopup col {
    display: block;
  }

  .recordingsPopup td.text-center {
    text-align: left !important;
  }

  .recordingsPopup button.close {
    top: -15px;
  }
}
@media (max-width: 1400px) and (min-width: 992px) {
  .patient_dashboard_page .row .col-6:nth-child(1) {
    padding-right: 5px;
  }

  .patient_dashboard_page .row .col-6:nth-child(2) {
    padding-left: 5px;
  }

  .patient_dashboard_page .average-section .card-body {
    padding: 1rem 0;
  }
}

@media (min-width: 768px) {
  .dashboard_page .patients_dir .add-to-patient-directory-btn {
    position: relative;
  }
}

.login_page .form-group .input {
  position: relative;
}
.login_page .form-group .input svg {
  position: absolute;
  right: 10px;
  top: 10px;
}
.login_page .form-group .input input[type="password"] {
  padding-right: 40px;
}

.password-wrapper {
  position: relative;
}
.password-wrapper svg {
  position: absolute;
  right: 30px;
  top: 10px;
}
.password-wrapper input[type="password"] {
  padding-right: 40px;
}

.profile_setup_page .form-group.row .col-sm-7 {
  position: relative;
}
.profile_setup_page .form-group.row .col-sm-7 svg {
  position: absolute;
  right: 30px;
  top: 10px;
}
.profile_setup_page .form-group.row .col-sm-7 input[type="password"] {
  padding-right: 40px;
}

.dashboard_page .patients_dir h2 {
  display: inline-block;
}
.dashboard_page .patients_dir .btn.add-to-patient-directory-btn {
  float: right;
  /* margin-top: 25px; */
  position: relative;
}
header.Site-header {
  z-index: 999;
  position: relative;
}

/* .exercise_card.card  .card-body .col-md-10.col-6{
  width: 100% !important;
} */

.exercise_card.card .mt-3.row .align-items-end {
  align-items: flex-end !important;
  flex-wrap: wrap;
  width: 100%;
  /*justify-content: flex-start !important;*/
}
.exercise_card.card .mt-3.row .align-items-end .btn {
  margin: 5px 10px 5px 0px !important;
}
.patient_excercises_page .Row_main_content .list-group {
  flex-wrap: wrap !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 45px;
  }
}

@media all and (max-width: 767px) {
  .scrollbar table.table {
    width: 100%;
  }
}
@media all and (max-width: 800px) {
  .ExercisePopup .video {
    position: relative !important;
    right: 0 !important;
    width: 100% !important;
  }
  .videoWrapper {
    padding-bottom: 0 !important;
    height: 300px !important;
  }
  .videoWrapper video {
    height: 100%;
  }
}

/**/

@media (min-width: 992px) and (max-width: 1199px) {
  .exercise_card.card .mt-3.row .align-items-end {
    align-items: center !important;
    width: 100%;
    justify-content: flex-start !important;
    display: flex !important;
  }
  .exercise_card.card .mt-3.row .align-items-end .btn {
    height: 25px;
  }
  .patient_excercises_page .btn {
    font-size: 9px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .exercise_card
    .mt-3.row
    .d-flex.justify-content-between.align-items-end.col-md-10 {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .patient_excercises_page .card h3.exercise-title {
    width: 70%;
  }
  .patients_dir #new td:nth-child(1) {
    max-width: 40% !important;
    font-size: 9px;
  }
  .patients_dir #new td:nth-child(2) {
    max-width: 10% !important;
    font-size: 9px;
  }
  .patients_dir #new td:nth-child(3) {
    max-width: 75px !important;
    font-size: 9px;
  }
  .dashboard_page .patients_dir button.btn.add-to-care-team-btn {
    min-width: 91px;
    padding: 5px 5px;
    font-size: 9px;
  }
  .Row_patient_report .dwld-card {
    flex-direction: column;
    min-height: 120px !important;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .exercise_card .col-md-2.col-6 .exersize_img img {
    height: 159px;
    width: 140px;
  }
  .exersize_img {
    max-width: 100%;
  }
}
@media (min-width: 421px) and (max-width: 575px) {
  .patients_dir #new td:nth-child(1) {
    max-width: 25% !important;
  }
  .patients_dir #new td:nth-child(3) {
    max-width: 110px !important;
  }
  .dashboard_page .patients_dir button.btn.add-to-care-team-btn {
    min-width: 110px;
    padding: 5px 5px;
    font-size: 10px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .exersize_img {
    max-width: 100%;
  }
  .patients_dir #new td:nth-child(1) {
    max-width: 30% !important;
  }
  .patients_dir #new td:nth-child(3) {
    max-width: 110px !important;
  }
  .dashboard_page .patients_dir button.btn.add-to-care-team-btn {
    min-width: 100px;
    padding: 5px 5px;
    font-size: 12px;
  }
}
.exercise-image-default {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  object-fit: contain;
  padding-top: 5px;
  padding-bottom: 5px;
}

.exercise-image-default img {
  display: block;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 150px;
  max-height: 130px;
  object-fit: fill;
}
.execonfirm-imgheight img {
  min-height: 130px;
}
.dashboard_page.container .sb--padding {
  padding-right: 0px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .exercise-image-deault {
    align-items: flex-start;
  }
  .exercise-image-deault img {
    min-height: 100px;
    min-width: 100px;
  }

  .row-div--padding {
    padding: 10px;
  }
  .patient_dashboard_page .row-div--padding h2 {
    display: flex;
  }

  .value_boxes {
    flex-direction: column;
  }
  .value_boxes .card {
    width: 100%;
  }
}

/*RTM*/
.Row_patient_report .choose-range {
  margin-top: 10px;
}

.Row_patient_report .radio-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.Row_patient_report .specific-claim {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dateWrapper .spanTxt {
  padding: 0px 10px;
}

.dateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateWrapper .date-range-FT {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-datepicker-wrapper .react-datepicker__input-container .dateWrap {
  width: 104px;
  padding: 8px;
  height: 35px;
}
.react-datepicker-wrapper .react-datepicker__input-container .RightdateWrap {
  width: 100%;
  height: 30px;
}
.react-datepicker__input-container {
  width: 100px;
  display: flex;
  align-self: flex-end;
}

.dateWrap {
  background-image: url("../src/images/calendarIcon.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 98%;
  font-size: 13px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ffffff;
  outline: none;
}

.column.col-md-8 {
  padding-right: 0px !important;
}

.claim {
  width: 95%;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 13px;
  height: 35px;
  padding: 8px;
  outline: none;
}
.Row_patient_report .purple-dot-table {
  /* position: relative; */
  /* padding-left: 10px; */
}
.Row_patient_report .purple-dot-table thead {
  z-index: 1;
}

td.activeDot {
  position: relative;
}
td.activeDot span:before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--primaryColor);
  position: absolute;
  content: " ";
  left: 8px;
  top: 40%;
  /* z-index: 999; */
}

.transmissionDays {
  width: 100%;
  height: 80px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
}
.transmissionDays .content .title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--primaryColor);
  text-align: center;
}
.transmissionDays .content .count {
  font-size: 20px;
  font-weight: bold;
}
.transmissionDays .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.patientReportFilter .btn-filter {
  background-color: var(--primaryColor);
  color: #fff;
  padding: 6px;
  box-shadow: none;
}
.patientReportFilter .btn-filter svg {
  fill: #fff;
  stroke: #fff;
  color: var(--primaryColor);
}

.patientReportFilter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0px;
}

.Row_patient_report .table td input[type="checkbox"]:checked {
  accent-color: #fff;
}
.Row_patient_report .table td input[type="checkbox"] {
  height: 16px;
  width: 16px;
  background: #ffffff;
  border: 1px solid #c6c2c2;
  border-radius: 3px;
}

.Row_patient_report .table {
  text-align: center;
  vertical-align: middle;
  /* background-color: #ffffff; */
}
.Row_patient_report .table > :not(caption) > * > * {
  border-bottom-width: 0px;
}
.Row_patient_report .table tbody tr td:not(:first-child) {
  background-color: #ffffff;
}
.transmissions-table {
  overflow-y: auto;
  height: 140px;
}
.transmissions-table thead {
  position: sticky;
  top: -1px;
}
.Row_patient_report .table tbody td:has(.table) {
  padding: 0px;
}

.Row_patient_report .table td .table tbody tr:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.Row_patient_report .table thead {
  text-align: center;
}

.Row_patient_report .table thead th:not(:first-child) {
  background-color: var(--primaryColor);
}
.Row_patient_report .table tr td:not(:first-child) {
  background-color: transparent;
}

.Row_patient_report thead tr {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
}

.patient_report_page .claim:disabled {
  /* color: #ffffff; */
}
.patient_report_page .pointer-none {
  pointer-events: none;
  appearance: none;
}
.patient_report_page
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .dateWrap:disabled {
  color: #ffffff;
  filter: grayscale(1);
  pointer-events: none;
}
.dividerLine {
  color: #cccccc;
  margin: 10px 0px !important;
}
.purple-dot-table .table thead tr th:first-of-type {
  /* background-color: var(--primaryColor-light); */
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-bottom-style: hidden !important;
}

.purple-dot-table .table tbody tr .firsttd:first-of-type {
  /* background-color: var(--primaryColor-light); */
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-bottom-style: hidden !important;
}
/*interactive notes*/
.inotesPopup .modal-dialog {
  max-width: 450px;
}
.inotesPopup .modal-header {
  padding: 0px 10px 0px 15px;
  border-bottom: 1px solid #dee2e6;
}
.inotesPopup .modal-body {
  padding: 0px 1rem;
}
.inotesPopup .modal-footer {
  border: 0;
  padding: 0px 0.75rem 0.75rem 0.75rem;
}
.inotesPopup textarea {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  resize: none;
  margin-bottom: 10px !important;
}

.inotesPopup .inotes--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.inotesPopup .inotes--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inotesPopup .inotes--row .react-datepicker-wrapper {
  margin-left: 10px;
}
.inotes--row .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inotes--row .form-group label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}
.inotes--row .inotes--input {
  width: 50px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  margin-right: 10px;
}
.previous-notes .pn-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--primaryColor);
}
.previous-notes .pn--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 6px;
}
.previous-notes .pn--content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  word-break: break-all;
}
.previous-notes .pn--item {
  border-bottom: 1px solid #e0e0e0;
  margin: 5px 0px 5px 0px;
  padding-bottom: 5px;
}
.pnotes_details--scrollbar .row:last-of-type .col {
  border-bottom: 0px;
}
.inotesPopup .pnotes_details--scrollbar {
  width: 420px;
  max-height: 150px !important;
  overflow: auto !important;
  padding: 15px;
  background: #f2f2f2;
  border-radius: 6px;
}

.inotesPopup
  .div-more-less-show
  span
  > span:nth-child(1)
  > span:nth-child(1)
  > span {
  font-weight: 400;
  color: #4f4f4f;
}
.helpdesk_page_btn {
  background: var(--primaryColor);
  border-radius: 6px;
  width: 91px;
  height: 39px;
}
.helpdesk_header {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: var(--primaryColor);
}
.helpdesk_btn_txt {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  position: relative;
  right: -225px;
}
.helpdesk_hotline_card {
  width: 100%;
  height: 200px;
  top: 210px;
  background: var(--primaryColor);
  border-radius: 6px;
}
.helpdesk_hotline_header {
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  color: #ffffff;
}
.helpdesk_hotline_subheader {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.helpdesk_hotline_content {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.helpdesk_max_height {
  /* height: 650px; */
  height: max-content;
}
.pn--content .show-more-less-clickable {
  font-weight: bold;
}
.graph--heading {
  color: var(--primaryColor);
}

#helpdesk_textarea {
  resize: none;
}
.truncate-text {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.truncate-txt--heading {
  max-width: 150px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
}
.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container1 .heading span {
  margin-left: 10px;
}
.rest-exec--input:disabled {
  background-color: #ffffff;
}
.react-datepicker__day--selected {
  background-color: var(--primaryColor) !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #000000 !important;
}

.reports_dashboard_page .form-label h6 {
  color: var(--primaryColor);
  font-size: 14px;
  margin-bottom: 0px;
}
.reports-table {
  border-color: #ffffff;
}
.reports-table th {
  background-color: var(--primaryColor);
  color: #ffffff;
  text-align: center;
}
.reports-table td {
  background-color: #e9ebf5;
  text-align: center;
}
.btn-fixed-size {
  width: 100px;
  height: auto;
  word-wrap: break-word;
  margin-bottom: 10px;
}
.table-container {
  overflow-x: auto;
  max-width: 100%;
}
.sort-icns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort-icns svg {
  cursor: pointer;
  margin: 0 10px;
}

.rep_pagination {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  cursor: pointer;
  margin: 30px;
}

.rep_pagination a {
  padding: 10px;
  border-radius: 5px;
  color: var(--primaryColor);
}

.rep_pagination__link {
  font-weight: bold;
}
.rep_pagination__link--active a {
  color: #fff;
  background: var(--primaryColor);
}

.rep_pagination__link--disabled a {
  color: rgb(198, 197, 202);
}

.reports_dashboard_page .dropdown-toggle::after {
  display: none !important;
}
.branchPopup .clinics-scrollbar {
  height: calc(100% - 5px) !important;
  max-height: 150px !important;
  overflow: auto !important;
}
.selectwithlabel .clinics-scrollbar {
  height: 120px !important;
  max-height: 150px !important;
  overflow: auto !important;
}
.branchPopup .clinics-scrollbar .clinics-scrollbar-infi,
.selectwithlabel .clinics-scrollbar .clinics-scrollbar-infi {
  overflow-x: hidden !important;
}
.library_console h2 {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;
  color: var(--primaryColor);
}
.lconsole--left {
  padding: 10px 50px 10px 50px;
}
.lconsole--left h1 {
  margin: 0 !important;
}
.lc--left-box {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  padding: 20px;
}
.add-new-exercise {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.custom-exercises {
  margin-top: 10px;
}
.library_console .btn,
.save-routine-modal .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 6px;
  padding: 8px;
  margin-right: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.loadingOverlay {
  display: flex !important;
  justify-content: center;
}
.loadingOverlay .modal-content {
  border: 0px;
}
.loadingOverlay .modal-dialog {
  max-width: 0px;
}
.qrcode-display {
  width: auto;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customex-mainhead {
  color: var(--primaryColor);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px;
}
.qrcCustomex-upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c2c2;
  border-radius: 8px;
  /* width: 150px; */
  padding: 10px;
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
}
.qrcCustomex-upload-btn svg {
  margin-right: 10px;
}

.qrcCustomExe-label {
  font-size: 10px;
  font-weight: 500;
}
.customex-upload-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: var(--primaryColor);
  flex-direction: column;
}
.customex-button-label .close-cross,
.qrcCustomExe-label .close-cross {
  color: red;
  cursor: pointer;
  margin-left: 10px;
}
.customex-upload-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.routine-container {
  padding: 0 20px 20px 20px;
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.overlay-lightbgcolor {
  background-color: var(--primaryColor-light);
}
.recard-container {
  border: 1px solid var(--primaryColor);
  background-color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
}
.recard-container .form-control:not(.recard-instruct) {
  max-width: 30px;
}
.recard-container .recard-title {
  display: flex;
  justify-content: space-between;
  color: var(--primaryColor);
  align-items: center;
  font-weight: 600;
  font-size: 18px;
}
.recard-typecount {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
}
.recard-typecount .recard-counts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.recard-typecount .recard-counts label {
  margin-right: 10px;
}
.recard-typecount .recard-counts button.btn.btn-primary {
  width: 20px;
  height: 100%;
  padding: 0 5px;
  color: #55595c;
  background-color: #eceeef;
  border-color: #eceeef;
}

.recard-typecount .input-group input {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.recard-typecount .input-group {
  flex-wrap: nowrap !important;
}
.recard-typecount .radio-label {
  font-size: 10px;
}

.recard-instruct {
  font-size: 12px;
}
.recard-container .MuiFormGroup-root {
  flex-wrap: nowrap !important;
}
.recard-container .MuiFormGroup-root .MuiTypography-root {
  font-size: 12px !important;
}
.save-routine-modal .modal-dialog {
  max-width: 850px;
}
.save-routine-name-modal .modal-dialog {
  max-width: 450px;
}
.save-rn-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 30px 0px 30px;
}
.save-rn-container .heading {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  margin-bottom: 10px;
}

.save-rn-container .condition-input {
  outline: none;
  text-align: left;
  min-width: 370px;
  width: 100%;
  height: 30px;
  border: 0;
  border-radius: 3px;
}

.newrout-container .lib-scrollbar-customex .infinite-scroll-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 3px;
}
.newrout-container .btn {
  min-width: 100px;
}

.recard-container .react-datepicker-popper {
  z-index: 3;
}

.preview_exercise .preview_exercise-list {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.wcam-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wcam-btngroup {
  max-width: 400px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.wcam-btngroup .btn {
  width: 100%;
  max-width: 130px;
}
.wcam-Container .back-link {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wcam-Container .camContainer {
  border: 1px solid #cccccc;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

.lib-leftpanel-searchbox {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frequency-table {
  background-color: var(--primaryColor);
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.frequency-table td:not(:first-of-type) {
  background-color: #ffffff;
  color: #000000;
}

.frequency-table .radio_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports_dashboard_page .pat-count-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reports_dashboard_page .pat-count-view div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.reports_dashboard_page .pat-count-view h6 {
  margin-bottom: 0px;
  font-size: 13px;
}
.reports_dashboard_page .pat-count-view div span {
  border: 1px solid #cbcbcb;
  padding: 2px 15px;
  margin-left: 10px;
  font-weight: bold;
}

.reports_dashboard_page .download-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primaryColor);
  cursor: pointer;
}

/* Hide the dropdown content by default */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.download-btn:hover .dropdown-content {
  display: block;
}

.dropdown-content span {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
}
.dropdown-content {
  right: 120px;
}

.date-line {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 4px solid var(--primaryColor);
  max-width: auto;
}

.date-line span {
  position: relative;
  font-size: 9px;
  padding: 1px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #000000;
  position: absolute;
  left: 10px;
  top: -2px;
  transform: translate(-50%, -50%);
}

.routine-label-container {
  width: 100%;
  overflow: hidden;
}
.routine-label-container div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsis-div {
  width: 100%;
  overflow: hidden;
}

.ellipsis-label {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis-dropdown-select {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mce-modal .modal-dialog {
  max-width: 500px;
}
.mce-modal .modal-header {
  font-weight: 700;
}
.mce-modal .btn-primary {
  padding: 2px 10px;
  font-weight: 500;
  font-size: 14px;
}
.mce-modal .btn-default {
  padding: 2px 10px;
  font-weight: 500;
  font-size: 14px;
}

/* Orphan patients Modal popup styles */
.orphanpatientsTable {
  width: 100%;
  text-align: center;
  border-spacing: 0;
}
.orphanpatientsPopup .btn {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0;
  color: #fff;
  font-weight: bold;
  padding: 2px 10px;
  margin: 0px;
  font-size: 14px;
}

.orphanpatientsPopup .orphanpat-scrollbar {
  height: 270px;
  overflow: auto !important;
}

.orphanpatientsPopup .modal-dialog {
  max-width: 1150px;
}

.orphanpatientsPopup .table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.1rem;
}

.assignemntsPopup #back_btn_conclude {
  border: 1px solid #cccccc;
  color: #0e0e0e;
  font-weight: bold;
  padding: 5px 30px;
  font-size: 14px;
  min-width: 0px;
  margin: 0px !important;
  background: #ffffff;
}

/* workout hepbuilder */
.HEPBPopup .modal-dialog {
  width: 100%;
  max-width: 1200px !important;
}
.HEPBPopup .workout-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.HEPBPopup .workout-scrollbar {
  height: calc(100vh - 400px) !important;
  min-height: 500px !important;
  overflow: auto !important;
}
.wrconfirm-container {
  max-height: 400px !important;
  overflow: auto !important;
}
.sw-border {
  margin: 5px;
  border-radius: 6px;
  border: 1px solid var(--primaryColor);
}
.hepb--close {
  width: 200px;
  display: flex;
  justify-content: flex-end;
}

.exe-prescription h5 {
  color: var(--primaryColor);
  font-size: 18px;
}
.exe-prescription span span {
  font-weight: 700;
}
.save--icon {
  color: var(--primaryColor);
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-self: flex-start !important;
  margin-left: 10px;
  cursor: pointer;
}
.ExercisePopup .modal-dialog,
.HEPBPopup .modal-dialog {
  margin-top: 50px;
}
.bcard-container .recard-typecount .recard-counts button.btn.btn-primary {
  min-width: 0px !important;
  margin: 0px;
}
.bcard-container .recard-typecount .input-group input {
  margin-bottom: 0px !important;
}
.bcard-container .bcast-videoWrapper {
  width: 220px;
  height: 220px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bcard-container .bcast-videoWrapper img,
.bcard-container .bcast-videoWrapper video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.bcard-container .recard-typecount {
  justify-content: center !important;
}
.bcast-videoWrapper .vid-load--container {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  object-fit: "contain";
  object-position: "center center";
}
.bcast-videoWrapper .img-load--container {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: "contain";
  object-position: "center center";
}

.broadcast-dropdown-container {
  width: 100%;
  /* margin: 0 auto;
    position: relative;
    margin-top: 15px;
    margin-bottom: auto;
    margin-bottom: 100px; */
}

.broadcast-dropdown-container .dropdown-wrapper {
  position: relative;
}

.broadcast-dropdown-container .dropdown-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
}

.broadcast-dropdown-container .search-input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.broadcast-dropdown-container .dropdown-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.broadcast-dropdown-container .clinic-dropdown-menu {
  position: static;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px;
}

.broadcast-dropdown-container .options-container {
  flex-grow: 1;
  margin-left: 10px;
}

.broadcast-dropdown-container .select-all-footer {
  padding: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #ced4da;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
}

.selected-badge {
  margin-right: 0.5rem;
  display: inline-block;
  cursor: pointer;
  padding-right: 0.9rem;
  position: relative;
  color: black;
  font-weight: normal;
  border: 1px solid rgb(228, 228, 228);
}

.selected-badge .remove-icon {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.badge-option {
  background-color: rgb(228, 224, 224);
  border: rgb(228, 224, 224);
  margin: 3px;
  border-radius: 3px;
}
.broadcast-dropdown-container .select-all-footer {
  flex-shrink: 0;
  padding: 0.5rem;
  position: sticky;
  bottom: 0;
}

/* Comment modal */
.comment-modal .modal-dialog {
  width: 600px;
}
.comment-modal .comment-modal-header {
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
}
.comment-modal .comment-field {
  border-radius: 0px;
  border: 1px solid #a3a3a3cc;
}
.comment-modal .modal-footer .btn {
  font-size: 16px;
}

.unireqapprovalview_container {
  max-height: 400px;
  overflow: auto;
}
/*scroll bar slim*/
.scrollbar_light::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scrollbar_light::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}
.scrollbar_light::-webkit-scrollbar-thumb {
  background: #999999;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
.HEPBPopup .search-filter-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.HEPBPopup .search-filter-top .search {
  flex: 1;
}
.HEPBPopup .search-filter-top .form-control {
  margin-bottom: 0px;
}

.truncate-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  border-bottom-width: 0px;
}

.rtm-switch .form-check-input {
  margin-left: 10px !important;
  float: none !important;
}

.drapndrop-dashline {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23666' stroke-width='3'  stroke-dasharray='6%2c 11' stroke-dashoffset='-35' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 16px;
  width: 100%;
  font-size: 12px;
}

.clickhere-text {
  color: var(--primaryColor);
  padding: 5px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.infotext-white {
  color: #000000;
  padding: 5px;
  margin-bottom: 0px !important;
}
.info-mail-text {
  color: var(--primaryColor);
  font-weight: bold;
}

.info-icon {
  color: var(--primaryColor);
  font-size: 25px;
}
.info-modal .modal-body .close {
  color: var(--primaryColor);
  font-size: 20px;
}
.info-modal .modal-body {
  margin: 5px;
}
.info-modal .modal-dialog {
  max-width: 600px;
}
.copy-icon {
  font-size: 16px;
  cursor: pointer;
}
